/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Confetti animation
Credit: CodePen Home css confetti Andy 
source: https: //codepen.io/andyobrien/pen/WYrxMe*/

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.confetti-piece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: #ffd300;
  top: 0;
  opacity: 0;
}

.confetti-piece:nth-child(1) {
  left: 7%;
  transform: rotate(-13deg);
  -webkit-animation: makeItRain 100ms infinite ease-out;
  animation: makeItRain 100ms infinite ease-out;
  -webkit-animation-delay: 344ms;
  animation-delay: 344ms;
  -webkit-animation-duration: 875ms;
  animation-duration: 875ms;
}

.confetti-piece:nth-child(2) {
  left: 14%;
  transform: rotate(70deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 251ms;
  animation-delay: 251ms;
  -webkit-animation-duration: 1085ms;
  animation-duration: 1085ms;
}

.confetti-piece:nth-child(3) {
  left: 21%;
  transform: rotate(-32deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 366ms;
  animation-delay: 366ms;
  -webkit-animation-duration: 754ms;
  animation-duration: 754ms;
}

.confetti-piece:nth-child(4) {
  left: 28%;
  transform: rotate(21deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 82ms;
  animation-delay: 82ms;
  -webkit-animation-duration: 1007ms;
  animation-duration: 1007ms;
}

.confetti-piece:nth-child(5) {
  left: 35%;
  transform: rotate(-29deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 211ms;
  animation-delay: 211ms;
  -webkit-animation-duration: 780ms;
  animation-duration: 780ms;
}

.confetti-piece:nth-child(6) {
  left: 42%;
  transform: rotate(-65deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 198ms;
  animation-delay: 198ms;
  -webkit-animation-duration: 957ms;
  animation-duration: 957ms;
}

.confetti-piece:nth-child(7) {
  left: 49%;
  transform: rotate(-2deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 146ms;
  animation-delay: 146ms;
  -webkit-animation-duration: 1072ms;
  animation-duration: 1072ms;
}

.confetti-piece:nth-child(8) {
  left: 56%;
  transform: rotate(-63deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 177ms;
  animation-delay: 177ms;
  -webkit-animation-duration: 879ms;
  animation-duration: 879ms;
}

.confetti-piece:nth-child(9) {
  left: 63%;
  transform: rotate(49deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 248ms;
  animation-delay: 248ms;
  -webkit-animation-duration: 1142ms;
  animation-duration: 1142ms;
}

.confetti-piece:nth-child(10) {
  left: 70%;
  transform: rotate(62deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 73ms;
  animation-delay: 73ms;
  -webkit-animation-duration: 990ms;
  animation-duration: 990ms;
}

.confetti-piece:nth-child(11) {
  left: 77%;
  transform: rotate(42deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 64ms;
  animation-delay: 64ms;
  -webkit-animation-duration: 1124ms;
  animation-duration: 1124ms;
}

.confetti-piece:nth-child(12) {
  left: 84%;
  transform: rotate(23deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 191ms;
  animation-delay: 191ms;
  -webkit-animation-duration: 833ms;
  animation-duration: 833ms;
}

.confetti-piece:nth-child(13) {
  left: 91%;
  transform: rotate(-12deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 134ms;
  animation-delay: 134ms;
  -webkit-animation-duration: 1122ms;
  animation-duration: 1122ms;
}

.confetti-piece:nth-child(odd) {
  background: #17d3ff;
}

.confetti-piece:nth-child(even) {
  z-index: 1;
}

.confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
}

.confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
  -webkit-animation-duration: 2500ms;
  animation-duration: 2500ms;
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}

.confetti-piece:nth-child(4n-7) {
  background: #ff4e91;
}

@-webkit-keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(600px);
  }
}

@keyframes makeItRain {
  from {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(600px);
  }
}


/* Title text with masked background. 
Credit: https: //codepen.io/Jintos */

/* Clip text element */
.clip-text {
  /* font-size: 6em; */
  font-weight: bold;
  line-height: 1;
  position: relative;
  display: inline-block;
  /* margin: .25em;
  padding: .5em .75em; */
  text-align: center;
  /* Color fallback */
  color: #fff;
  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}

.clip-text:before,
.clip-text:after {
  position: absolute;
  content: '';
}

/* Background */
.clip-text:before {
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-image: inherit; */
}

/* Text Background (black zone) */
.clip-text:after {
  position: absolute;
  z-index: -1;
  top: .125em;
  right: .125em;
  bottom: .125em;
  left: .125em;
  background-color: #000;
}

/* Change the background position to display letter when the black zone isn't here */
.clip-text--no-textzone:before {
  background-position: -100.75em 0;
}

.clip-text--no-textzone:after {
  content: none;
}

/* Use Background-size cover for photo background and no-repeat background */
.clip-text--cover,
.clip-text--cover:before {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
}


/* ------------------- exp animation ------------------------- */

.pointer {
  --size: 30px;
  pointer-events: none;
  width: var(--size);
  height: var(--size);
  background: rgba(247, 56, 89, 0.2);
  position: fixed;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0);
  transition: transform 0.3s;
  border: 2px solid rgba(247, 56, 89, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.pointer:before {
  content: "";
  width: 80%;
  height: 80%;
  position: absolute;
  display: block;
  background: #141829;
  border-radius: 50%;
  transition: background 0.5s, box-shadow 0.5s;
}

.is-pressed .pointer {
  transform: translate3d(-50%, -50%, 0) scale(1);
}

.is-longpress .pointer {
  animation: wobble 0.2s infinite alternate;
}

.is-longpress .pointer:before {
  background: #faf15d;
  box-shadow: 0 0 5px rgba(250, 241, 93, 0.5);
  animation: wobble2 0.2s infinite alternate;
}

@keyframes wobble {
  to {
    transform: translate3d(-50%, -50%, 0) scale(0.5);
  }
}

@keyframes wobble2 {
  to {
    transform: scale(0.5);
  }
}

/* --------------------------- Our css ------------------------ */

@font-face {
  font-family: Unbounded;
  src: url(./assets/font/Unbounded-Bold.ttf);
}

body {
  font-family: 'Unbounded', cursive;
  /* background-image: url("./assets/background/bg2.jpg"); */
  background-size: cover;
  /* background-repeat: no-repeat; */
  /* background-color: rgb(0, 187, 255); */
  background: linear-gradient(320.54deg, #00069F 0%, #120010 72.37%), linear-gradient(58.72deg, #69D200 0%, #970091 100%), linear-gradient(121.28deg, #8CFF18 0%, #6C0075 100%), linear-gradient(121.28deg, #8000FF 0%, #000000 100%), linear-gradient(180deg, #00FF19 0%, #24FF00 0.01%, #2400FF 100%), linear-gradient(52.23deg, #0500FF 0%, #FF0000 100%), linear-gradient(121.28deg, #32003A 0%, #FF4040 100%), radial-gradient(50% 72.12% at 50% 50%, #EB00FF 0%, #110055 100%);
  background-blend-mode: screen, color-dodge, color-burn, screen, overlay, difference, color-dodge, normal;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
}

.content {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */

}

.sidebar {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  width: 200px;
  height: 97%;
  padding: 0px 20px;
  border-radius: 10px;
  margin: 10px;
  background-color: rgb(0, 147, 180);
  /* justify-content: space-between; */
  box-shadow: 0px 10px 20px 10px rgba(97, 142, 160, 0.226);
  /* z-index: 3; */
  background-image: url("./assets/background/bg4.jpg");
  background-size: cover;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to bottom, #78ffd6, #a8ff78); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to bottom, #78ffd6, #a8ff78); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.profile {
  margin: 0px 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.226);
  z-index: 10;
  width: 100%;
  height: 80px;
  background-color: rgb(149, 141, 141);
  background: linear-gradient(120deg, #FFFFFF 0%, #FF006B 100%), linear-gradient(235deg, #FFFFFF 0%, #FF006B 100%), linear-gradient(235deg, #FFFFFF 0%, #000000 100%), linear-gradient(90deg, #FFE037 0%, #FFE037 40%, #1DCD9F 40%, #1DCD9F 50%, #088C6F 50%, #088C6F 70%, #23033C 70%, #23033C 100%);
  background-blend-mode: overlay, overlay, overlay, normal;
  border-radius: 10px;
}

.game-control {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* width: 100%; */
  margin: 10px;
  padding: 10px 0px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: wheat; */

  margin: 10px;
  font-size: 12px;
  width: 100%;
  border-radius: 10px;
  z-index: 10;
}

.joined-players-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}




.line {
  width: 40px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.266);
  margin: 0px 6px;
}

.board-holder {
  margin: 10px;
  margin-top: 30px;
}

.board {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.hole {
  position: absolute;
  top: 20px;
  left: 20px;
  /* width: 200px;
  height: 200px; */
  /* width: 100%;
  height: 100%; */
  border-radius: 50%;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 1);
  z-index: 5;
  transform-origin: center;
  transition: all 0.3s;
}

.freeze-view {
          display: flex;
          justify-content: center;
          align-items: center; 
          position: absolute;  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  /* background-color: #2195f373; */
  z-index: 11; 
  font-size: 64px;
  color: white;
  text-shadow: 0px 0px 20px #0486ff;
  scale: 1; 
  transition: all 0.3s;
}


.freeze-image { 
  /* background: #d3a34f; */
  width: 100px;
  height: 100px;
  background-image: url("./assets/image/freeze.svg");
  background-size: contain;  

}

.background {
  outline: 3px rgba(255, 255, 255, 0.452) solid;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.619);
  -webkit-box-shadow: 0px 0px 105px 14px rgba(46, 213, 255, 0.53);
  -moz-box-shadow: 0px 0px 105px 14px rgba(46, 213, 255, 0.53);
  box-shadow: 0px 0px 105px 14px rgba(46, 213, 255, 0.53);
}


.wall {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* background: #d3a34f; */
  /* background-image: url("./assets/image/wall.svg"); */
  background-size: contain;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #1e9600, #fff200); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #1e9600, #fff200); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  z-index: 2;

}


.artifact {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-size: contain;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #1e9600, #fff200); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #1e9600, #fff200); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-size: 0.8rem;
  z-index: 2;
  animation: zoomOut;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 8s;
  animation-delay: 8s;
  /* don't forget to set a duration! */
  scale: 0.8;

}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joined-player {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 6px;
  border-radius: 10px;
  margin: 0px 2px;
  /* -webkit-box-shadow: 0px 0px 93px 4px rgba(46, 213, 255, 0.74);
  -moz-box-shadow: 0px 0px 93px 4px rgba(46, 213, 255, 0.74);
  box-shadow: 0px 0px 93px 4px rgba(46, 213, 255, 0.74); */
}

.joined-players-details-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.joined-players-details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.joined-players-details-image {
  border-radius: 2px;
  width: 24px;
  height: 24px;
  background-image: url("./assets/image/tank.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px 3px;
}

.artifact-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  /* padding-left: 15px; */
}

.artifact-flag-content {
  height: 3em;
  width: 3em;
  /* position: absolute; */
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
  /* background-color: white; */
  font-size: 0.5rem;
  margin-left: -2em;
  margin-right: 2px;
  rotate: -10deg;
  scale: 1.2;
}

.weapon-stats {
  font-size: 0.5rem;
  padding: 0px 4px;
  border-radius: 2px;
  z-index: 3;
}

.attack-value {
  background-color: red;
}

.speed-value {
  background-color: yellow;
}

.health-value {
  background-color: greenyellow;
}

.winners-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;

}

.circle {
  position: absolute;
  width: 100px;
  height: 100px;
  align-items: center;
  padding: 30px;
  margin: 30px;
  background-color: rgb(0, 221, 255);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.5;
  /* border: 20px dotted aqua;  */
  box-shadow: 0px 0px 10px #e5ff00;
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
}

.scale {
  animation: scaleUp 0.2s;
  -webkit-animation: scaleUp 0.2s;
}

.tank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  transform-origin: center;
  /* background: rgb(255, 236, 0); */
  /* background: radial-gradient(circle, rgba(255, 236, 0, 1) 0%, rgba(228, 233, 148, 0) 80%); */
  background-size: contain;
  scale: 0.9;
  z-index: 3;
  transition: all 0.2s;
}


.target {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  border-radius: 50%;
  /* color: #FFDD09; */
  font-weight: bold;
  background-size: contain;
  scale: 0.66;
  z-index: 3;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.rotate {
  animation: rotate 10s linear infinite;
}

.element-svg-text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  border-radius: 50%;
  font-weight: bold;
  background-size: contain;
  scale: 1.2;
  z-index: 3;
  color: white;
  /* text-shadow: 0px 0px 4px black; */
}

.white-text {
  fill: white;
}


.bullet {
  position: absolute;
  z-index: 1;
  background-size: contain;
  /* transition: all 0.05s; */
}

.distructed {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 2px;
  color: red;
  font-weight: bold;
  background-image: url("./assets/image/fire.svg");
  background-size: contain;
  z-index: 5;
}

.onBoarding {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.onBoardingHolder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loggedIn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createGameInputHolder {
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border: 2px solid yellowgreen;
  background-color: yellow;
  border-radius: 20px;
  height: 300px;
  box-shadow: 0px 10px 10px 1px rgba(97, 160, 98, 0.226);

}

input {
  padding: 10px;
  margin: 4px;
  width: 100%;
  border-radius: 10px;
  font-size: 1em;
  font-family: 'Unbounded', cursive;
}

select {
  padding: 10px;
  margin: 4px;
  border-radius: 10px;
  font-size: 1em;
  font-family: 'Unbounded', cursive;
}

.square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0.2em;
  padding: 0.6em 0.6em;
  /* width: 100%; */
  background: #FFFFFF;
  border: none;
  border-radius: 0.4em;
  text-transform: uppercase;
  font-family: 'Unbounded', cursive;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.04em;
  perspective: 500px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.618);
}

.square>div {
  margin: 2px;
}

.over {
  text-decoration: wavy overline lime;
}

.line-through {
  text-decoration: solid line-through red 2px;
}

button {
  display: block;
  position: relative;
  /* width: 100%; */
  margin: 0.2em;
  padding: 0.5em 1.2em;
  cursor: pointer;
  background: #FFFFFF;
  border: none;
  border-radius: 0.4em;
  text-transform: uppercase;
  font-family: 'Unbounded', cursive;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.04em;
  perspective: 500px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.246);
}

button:hover {
  background-color: #FFF65B;
  transition: background 0.3s 0.1s;
}

button:hover:before {
  --z: 0.04;
  -webkit-animation: translateWobble 2.2s ease forwards;
  animation: translateWobble 2.2s ease forwards;
}

button:hover:after {
  --z: -0.06;
  -webkit-animation: translateWobble 2.2s ease forwards;
  animation: translateWobble 2.2s ease forwards;
}